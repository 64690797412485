// <div data-controller="components--nicepay">
//
// </div>
import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";
import helpers from "@/utils/helpers";

export default class extends Controller {
  static targets = ["cardNumber", "form", "submit", "register"];

  cardFormat(value) {
    const v = value.replace(/(\s+|-)/g, '').replace(/[^0-9]/gi, '');
    const matches = v.match(/\d{4,16}/g);
    const match = matches && matches[0] || '';
    const parts = [];

    const len = match.length;
    let i;

    for (i = 0, len; i < len; i += 4) {
      parts.push(match.substring(i, i + 4))
    }

    if (parts.length) {
      return parts.join('-')
    } else {
      return value
    }
  }

  onEditCardNumber = (e) => {
    const typedNumber = e.target.value;
    this.cardNumberTarget.value = this.cardFormat(typedNumber);
  };

  onClickRegister(e) {
    if (!this.formTarget.checkValidity()) {
      this.submitTarget.click();
      return false;
    }
    const prevText = this.registerTarget.innerText;

    this.registerTarget.innerText = "처리중 입니다..";
    this.registerTarget.disabled = true;
    this.submitTarget.click();
    setTimeout(() => {
      this.registerTarget.innerText = prevText;
      this.registerTarget.disabled = false;
    }, 3000)
  }

  preventKeyClick = (e) => {
    const keyCode = e.keyCode || e.which;

    if (keyCode === 13) {
      e.preventDefault();
      return false;
    }
  };

  connect() {
    this.cardNumberTarget.addEventListener("keyup", this.onEditCardNumber);
    this.formTarget.addEventListener("keypress", this.preventKeyClick)
  }

  disconnect() {
    this.cardNumberTarget.removeEventListener("keyup", this.onEditCardNumber);
    this.formTarget.removeEventListener("keypress", this.preventKeyClick)
  }
}