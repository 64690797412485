// <div data-controller="components--remote">
//
// </div>
import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";
import helpers from "@/utils/helpers";

export default class extends Controller {
  static targets = [ "" ];

  openSubscriptionModal(e) {
    this.subscriptionModalController.showModal();
  }

  openMembershipModal(e) {
    this.subscriptionModalController.showModal("paid");
  }

  openLoginModal(e) {
    if (this.loginModalController) {
      this.loginModalController.showModal();
    } else if (this.subscriptionModalController) {
      this.subscriptionModalController.showModal(); // unsubscribe_html(subscription:, type:) 대응
    }
  }

  openCheckoutModal(e) {
    this.checkoutModalController.showModal();
  }

  get subscriptionModalController() {
    return this.application.getControllerForElementAndIdentifier(document.getElementById("subscribeModal"), "components--subscribe-modal")
  }

  get loginModalController() {
    return this.application.getControllerForElementAndIdentifier(document.getElementById("loginModal"), "components--modal")
  }

  get checkoutModalController() {
    return this.application.getControllerForElementAndIdentifier(document.getElementById("checkoutModal"), "components--modal")
  }

  connect() {

  }
}