// <div data-controller="notes--draft">
//
// </div>
import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";
import helpers from "@/utils/helpers";

// https://stimulus.hotwired.dev/reference
export default class extends Controller {
  static targets = [ "container" ];
  static classes = []
  static values = {
    editorData: Object,
    extId: String
  };

  initialize() {

  }

  get editorJsController() {
    return this.application.getControllerForElementAndIdentifier(document.getElementById("editorJsForm"), "components--editor-js")
  }

  listModalController(index) {
    return this.application.getControllerForElementAndIdentifier(document.getElementById("modal" + index), "components--modal")
  }

  recoverRepositoryData() {
    if (confirm("작성중이던 데이터를 덮어쓰고, 이 내용으로 복구 하시겠어요?")) {
      this.editorJsController.clearAndRenderDraft(this.editorDataValue);
      this.listModalController(this.extIdValue).hideModal();
    }
  }

  connect() {

  }

  disconnect() {

  }
}