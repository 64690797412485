// <div data-controller="components--email-validator">
//
// </div>
import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";
import * as helpers from "@/utils/helpers";

// https://stimulus.hotwired.dev/reference
export default class extends Controller {
  static targets = [ "email", "emailValidation" ];
  static classes = [ "" ];
  static outlets = [];
  static values = {};

  initialize() {
    this.emailValidnessTimeout = null;
  }

  validateEmail(e) {
    if (this.emailValidnessTimeout != null) {
      clearTimeout(this.emailValidnessTimeout);
    }

    const email = e.target.value;

    const mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.match(mailformat)) {
      this.emailValidnessTimeout = setTimeout(() => {
        this.emailValidnessTimeout = null;
        this.askEmailValidness(email);
      }, 100)
    } else {
      this.emailValidationTarget.className = "mt-1 text-xs text-red-700 dark:text-red-300";
      this.emailValidationTarget.innerText = "이메일 형식을 확인해주세요";
    }
  }

  askEmailValidness(email) {
    api.post("/api/users/validate_email", { email: email }).then((res) => {
      if (res.status === 200) {
        if (res.data.valid) {
          this.emailValidationTarget.className = "mt-1 text-xs text-green-700 dark:text-green-300";
          this.emailValidationTarget.innerHTML = res.data.msg;
        } else {
          this.emailValidationTarget.className = "mt-1 text-xs text-red-700 dark:text-red-300";
          this.emailValidationTarget.innerHTML = res.data.msg;
        }
      } else {
        alert(res.data.msg);
      }
    });
  }

  connect() {

  }

  disconnect() {

  }
}