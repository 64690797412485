// <div data-controller="components--slider">
//
// </div>
import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";
import helpers from "@/utils/helpers";
import Glide from '@glidejs/glide'

// const itemWidth = 200;
// const margin = 12;
export default class extends Controller {
  static targets = [ "" ];


  connect() {
    // console.log("connected");
    // this.cnt = 0;
    // const elemCnt = this.context.element.children.length;
    // this.maxScroll = elemCnt * itemWidth + margin * elemCnt - (4 * itemWidth + 4 * margin);
    // console.log(this.maxScroll);
    const glideUUID = this.data.get("glide-uuid");

    this.glide = new Glide(`.${glideUUID}`, {
      type: 'carousel',
      startAt: 0,
      perView: 4,
      // focusAt: 2.55,
      gap: 0,
      keyboard: false,
      animationDuration: 200,
      animationTimingFunc: "ease-in-out",
      dragThreshold: 10,
      swipeThreshold: 30,
      breakpoints: {
        1200: {
          gap: 0,
          startAt: 0,
          perView: 3.75,
          // focusAt: 0.85,
        },
        1023: {
          gap: 0,
          startAt: 0,
          perView: 1.5,
          // focusAt: 0.85,
        },
      }
    }).mount();
  }

  disconnect() {
    if (this.glide) {
      this.glide.destroy();
    }
  }
}