import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.title = this.context.element.children[0];
    this.subtitle = this.context.element.children[1];
    this.title.addEventListener("input", titleChange);
    this.subtitle.addEventListener("input", subtitleChange);
  }

  disconnect() {
    this.title.removeEventListener("input", titleChange);
    this.subtitle.removeEventListener("input", subtitleChange);
  }
}

const titleChange = (e) => {
  const {value} = e.target;
  onChange(value, "editor-title-change");
}

const subtitleChange = (e) => {
  const {value} = e.target;
  onChange(value, "editor-subtitle-change");
}

const onChange = (value, customEvent) => {
  const event = new CustomEvent(customEvent, {detail: value});
  window.dispatchEvent(event)
}