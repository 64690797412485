// <div data-controller="components--multi-checkbox">
//
// </div>
import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";
import * as helpers from "@/utils/helpers";

// https://stimulus.hotwired.dev/reference
export default class extends Controller {
  static targets = [ "checkbox" ];
  static classes = [ "" ];
  static outlets = [];
  static values = {};

  initialize() {

  }

  checkAll(e) {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = e.target.checked
    })
  }

  connect() {

  }

  disconnect() {

  }
}