import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import '../controllers'
import 'alpine-turbo-drive-adapter'
import 'alpinejs'
import "./zoom.min"

// Prevent duplicate initialization of Rails UJS
if (!window.Rails || !window._rails_loaded) {
    import("@rails/ujs").then((Rails) => {
        Rails.start();
    }).catch((error) => {
        console.error("Error loading Rails UJS:", error);
    });
}

ActiveStorage.start();

// https://turbo.hotwired.dev/handbook/streams#custom-actions
window.Turbo.StreamActions.scroll_to = function () {
    const target = this.targetElements[0];
    target.scrollIntoView({behavior: "auto", block: "start"});
};
