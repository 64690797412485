// <div data-controller="google-analytics--form">
//
// </div>
import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";
import * as helpers from "@/utils/helpers";

// https://stimulus.hotwired.dev/reference
export default class extends Controller {
  static targets = [ "" ];
  static classes = [ "" ];
  static outlets = [];
  static values = {};

  initialize() {

  }

  async event(e) {
    const eventParams = JSON.parse(e.target.dataset.eventParams);
    const eventName = eventParams.name;
    delete eventParams.name;

    // gtag 이벤트 전송 후 완료 시점을 기다리기 위한 Promise 설정
    await new Promise((resolve) => {
      if (typeof gtag === "function") {
        // gtag 이벤트 전송 시 event_callback 설정
        gtag('event', eventName, {
          ...eventParams,
          'event_callback': resolve // gtag 이벤트 완료 후 resolve 호출
        });
      } else {
        resolve(); // gtag 함수가 없을 경우 즉시 완료 처리
      }
    });
  }

  connect() {

  }

  disconnect() {

  }
}