// <div data-controller="components--flash">
//
// </div>
import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";

export default class extends Controller {
  static targets = [ "container" ];
  static values = {
    key: String,
    message: String,
    ttl: Number, // 3.0 업글 후 : ttl: { type: Number, default: 5 }
    withLink: Boolean
  };

  dismiss() {
    this.containerTarget.remove();
  }

  flashKeyToEmoji(flashKey) {
    switch(flashKey) {
      case 'success':
        return "😊";
      case 'error':
        return "🚫";
      case 'alert':
        return "❗️";
      case 'warning':
        return "😭";
      case 'notice':
        return "💡";
      case 'check':
        return "✔️";
      default:
        return "";
    }
  }

  flashTemplate(key, message) {
    return `<div ${this.withLinkValue ? `` : `data-action="click->components--flash#dismiss"`}
                class="${this.withLinkValue ? "" : "cursor-pointer "}max-w-sm w-full bg-slate-50 dark:bg-slate-800 shadow-lg rounded-lg pointer-events-auto hover:shadow-sm">
        <div class="rounded-lg border overflow-hidden">
            <div class="p-4">
              <div class="flex items-start">
                <div class="shrink-0">${this.flashKeyToEmoji(key)}</div>
                <div class="ml-3 w-0 flex-1 pt-0.5">
                  <p class="text-sm leading-5 font-medium text-slate-900 dark:text-slate-200">${message}</p>
                </div>
                <div class="ml-4 shrink-0 flex">
                  <button
                    data-action="click->components--flash#dismiss"
                    class="inline-flex text-gray-400 focus:outline-none focus:text-slate-600 dark:text-slate-300 transition ease-in-out duration-150">
                    <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
    </div>`;
  }

  connect() {
    let template;
    template = this.flashTemplate(this.keyValue, this.messageValue);
    this.containerTarget.innerHTML = template;

    setTimeout(() => {
      this.dismiss();
    }, this.ttlValue * 1000)
  }
}