// <div data-controller="posts--infinite">
//
// </div>
import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";
import helpers from "@/utils/helpers";

export default class extends Controller {
  static targets = [
      "postsContainer",
      "nextButtonContainer",
    "nextButton"
  ];
  static values = {
    page: Number,
    filterApi: String,
    loading: Boolean,
    finished: Boolean,
    params: Object,
    userSignedIn: Boolean,
    loadBy: String
  };

  loadPosts() {
    this.loadingValue = true;
    const currentPage = this.hasPageValue ? this.pageValue : 1;
    const nextPage = currentPage + 1;

    api.get(`${this.filterApiValue}`, {
      page: nextPage, ...this.paramsValue
    }).then((res) => {
      const responseDoc = new DOMParser().parseFromString(res.data, "text/html");
      const preRenderedPosts = responseDoc.getElementById("preRenderedPosts");
      if (preRenderedPosts) {
        const renderedPost = preRenderedPosts.innerHTML;
        this.postsContainerTarget.insertAdjacentHTML('beforeend', renderedPost);
        this.pageValue = nextPage;
        this.loadingValue = false;
        this.finishedValue = renderedPost.trim() === "";
        if (this.hasNextButtonContainerTarget) {
          this.nextButtonContainerTarget.remove();
        }
      }
    })
  }

  onScroll(e) {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 2000) {
      if (!this.loadingValue && !this.finishedValue) {
        this.loadPosts();
      }
    }
  }

  onClickNext(e) {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 2000) {
      if (!this.loadingValue && !this.finishedValue) {
        this.nextButtonTarget.innerText = "로딩중..";
        this.loadPosts();
      }
    }
  }

  connect() {
    if (this.loadByValue !== "button") {
      this.loadPosts();
    }
  }
}