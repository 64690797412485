// <div data-controller="posts--show">
//
// </div>
import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";
import helpers from "@/utils/helpers";

export default class extends Controller {
  static targets = [ "scrollSpy", "writerTopBar" ];

  initialize() {
    this.popupDisplayed = false;
    this.prevScroll = window.scrollY || 0;
  }

  scrollTo(e) {
    const { scrollId } = e.target.dataset;
    this.scrollIntoView(scrollId);
  }

  scrollIntoView(scrollId) {
    const scrollSection = document.getElementById(scrollId);
    if (scrollSection) {
      scrollSection.scrollIntoView({
        behavior: "auto",
        block: "center"
      })
    }
  }

  onScroll(e) {
    if (window.scrollY >= (document.body.offsetHeight * 0.5)) {
      if ((this.data.get("enable-popup") === "true") && (this.popupDisplayed === false)) {
        this.modalController.showModal();
        this.popupDisplayed = true;
      }
    }

    if (window.scrollY >= (200)) {
      const diff = this.prevScroll - window.scrollY;
      if (Math.abs(diff) < 20) {
        return
      }

      if (this.prevScroll <= window.scrollY) {
        this.writerTopBarTarget.classList.remove("sticky");
      } else {
        this.writerTopBarTarget.classList.add("sticky");
      }
      this.prevScroll = window.scrollY;
    }
  }

  get modalController() {
    return this.application.getControllerForElementAndIdentifier(document.getElementById("subscribeModal"), "components--subscribe-modal")
  }

  connect() {
    const anchor = window.location.hash.toString();
    if (anchor) {
      const elementId = anchor.replace("#", "");
      this.scrollIntoView(elementId)
    }
  }
}
