// <div data-controller="automated-emails--form">
//
// </div>
import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";
import * as helpers from "@/utils/helpers";

// https://stimulus.hotwired.dev/reference
export default class extends Controller {
  static targets = [ "deliveryDaysCheckBox" ];
  static classes = [ "" ];
  static outlets = [];
  static values = {};

  initialize() {

  }

  get flashesController() {
    return this.application.getControllerForElementAndIdentifier(document.getElementById("flashesContainer"), "components--flashes")
  }

  validateDeliveryDays(e) {
    const allUnchecked = this.deliveryDaysCheckBoxTargets.every((target) => !target.checked);

    if (allUnchecked) {
      this.flashesController.createFlash({
        key: "error",
        ttl: 10,
        message: "최소 하루 이상의 발송 가능 요일 선택이 필요합니다"
      });

      e.preventDefault();
    }
  }

  connect() {

  }

  disconnect() {

  }
}