// <div data-controller="comments--vote">
//
// </div>
import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";

export default class extends Controller {
  static targets = [ "votesCount", "upVote", "downVote" ];

  setVotesCount(count) {
    this.votesCountTarget.innerText = count;
  }

  vote(e) {
    const { positive } = e.target.dataset;
    api.post(`/api/comments/${this.data.get("ext-id")}/vote.json`, {
      positive: true
    }).then((res) => {
      const { votes_count, comment_vote } = res.data;
      this.setVotesCount(votes_count);

      // 색깔 리셋
      // this.downVoteTarget.className = "flex items-center text-gray-400 hover:text-green-700";
      this.upVoteTarget.className = "flex items-center text-gray-400 hover:text-green-700";
      if (comment_vote) {

        if (comment_vote.positive === true) {
          this.upVoteTarget.className = "flex items-center text-green-700 dark:text-green-700 hover:text-green-600";
        } else if (comment_vote.positive === false) {
          // this.downVoteTarget.className = "flex items-center text-green-700 dark:text-green-700 hover:text-green-600";
        }
      }
    });
  }


  connect() {

  }
}