// <div data-controller="components--iamport">
//
// </div>
import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";
import {appendScript} from "@/utils/helpers";

// https://docs.iamport.kr/tech/pg-parameter?lang=ko
// danal / kakaopay
export default class extends Controller {
  static targets = [ "submit", "response" ];

  initialize() {

  }

  loadScript = (callback) => {
    const impId = this.data.get("imp-id");
    if (window.IMP) {
      IMP.init(impId);
      callback(IMP);
    } else {
      appendScript("https://code.jquery.com/jquery-1.12.4.min.js", () => {
        appendScript("https://cdn.iamport.kr/js/iamport.payment-1.2.0.js", () => {
          IMP.init(impId);
          callback(IMP);
        });
      });
    }
  }

  fakeDanalSmsCallBack = () => {
    if (process.env.NODE_ENV === "production") {
      return false;
    }

    const fakeResponse = {
      imp_uid: "development",
      merchant_uid: this.data.get("merchant-uid"),
      success: true,
    }
    this.submitResponse(fakeResponse);
  }

  submitResponse = (rsp) => {
    if ( rsp.success ) {
      api.post(this.data.get("submit-url"), {
        response: rsp
      }).then((res) => {
        if (window.opener) { // 레거시 팝업 형식일 경우
          window.opener.location.reload(true);
          window.close();
        } else {
          window.location.reload();
        }
      });
    } else {
      if (process.env.NODE_ENV === "development") {
        alert(`[${rsp.error_coe}] ${rsp.error_msg}`)
      }
    }
  }

  startIdentityVerification() {
    // if (process.env.NODE_ENV === "development") {
    //   this.fakeDanalSmsCallBack();
    //   return;
    // }

    this.loadScript((iamport) => {
      iamport.certification({ // param
        merchant_uid: this.data.get("merchant-uid"), // 주문 번호
        m_redirect_url : this.data.get("submit-url"), // 모바일환경에서 popup:false(기본값) 인 경우 필수, 예: https://www.myservice.com/payments/complete/mobile
        popup : false // PC환경에서는 popup 파라메터가 무시되고 항상 true 로 적용됨
      }, this.submitIdentityVerification);
    });
  }

  submitIdentityVerification = (rsp) => {
    if ( rsp.success ) {
      api.post(this.data.get("submit-url"), {
        response: rsp
      }).then((res) => {
        window.location.href = res.data.redirect_url;
      });
    } else {
      if (process.env.NODE_ENV === "development") {
        alert(`[${rsp.error_coe}] ${rsp.error_msg}`)
      }
    }
  }

  startPayRequest(pg) {
    this.loadScript((iamport) => {
      iamport.request_pay({
        pg: pg || "kakaopay",
        merchant_uid : 'maily_so_' + new Date().getTime(),
        name : '메일리 결제 수단 등록하기',
        amount : 0, // 빌링키 발급만 진행하며 결제승인을 하지 않습니다.
        customer_uid : this.data.get("customer-uid"), //customer_uid 파라메터가 있어야 빌링키 발급이 정상적으로 이뤄집니다.
        buyer_email : this.data.get("customer-email"),
        buyer_name : this.data.get("customer-name")
      }, this.submitResponse);
    });
  }

  openKakaoPayPopup() {
    window.open("/app/personal/kakaopay", "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=450,height=600");
  }

  openCardPopup() {
    window.open("/app/personal/card", "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=450,height=600");
  }

  startKakaoPay() {
    this.startPayRequest("kakaopay");
  }

  connect() {

  }
}