// <div data-controller="components--share">
//
// </div>
import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";
import helpers from "@/utils/helpers";
import ClipboardJS from 'clipboard';

export default class extends Controller {
  static targets = [ "copyText" ];

  onClickShare(e) {
    e.preventDefault();
    if (navigator.share) {
      navigator.share({
        title: this.data.get("title"),
        text: this.data.get("text"),
        url: this.data.get("url"),
      }).then(() => console.log('Successful share'))
        .catch(console.error);
    }
  }

  connect() {
    if (navigator.share) {
      this.copyTextTarget.classList.remove("hidden");

      if (window.location.hash.slice(1) === "share") {
        this.onClickShare();
      }
    }
  }

  disconnect() {
  }
}