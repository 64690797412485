// https://boringrails.com/articles/hovercards-stimulus/
// https://codesandbox.io/s/github/popperjs/website/tree/master/examples/placement?file=/src/styles.css:387-407
// <div
//   class="inline-block"
//   data-controller="hovercard"
//   data-hovercard-url-value="<%= hovercard_shoe_path(shoe) %>"
//   data-action="mouseenter->hovercard#show mouseleave->hovercard#hide"
// >
//   <%= link_to shoe.name, shoe, class: "branded-link" %>
// </div>
import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";
import helpers from "@/utils/helpers";
import { createPopper } from '@popperjs/core';

// https://stimulus.hotwired.dev/reference
export default class extends Controller {
  static targets = ["guidance", "card"];
  static values = { url: String };

  show() {
    if (this.hasCardTarget) {
      this.cardTarget.classList.remove("hidden");
      createPopper(this.guidanceTarget, this.cardTarget, {
        placement: 'bottom-end',
      });
    } else {
      fetch(this.urlValue)
          .then((r) => r.text())
          .then((html) => {
            const fragment = document
                .createRange()
                .createContextualFragment(html);

            this.element.appendChild(fragment);
          });
    }
  }

  hide() {
    if (this.hasCardTarget) {
      this.cardTarget.classList.add("hidden");
    }
  }

  disconnect() {
    this.hide();
  }
}