// <div data-controller="components--flatpickr">
//
// </div>
import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";
import * as helpers from "@/utils/helpers";
import flatpickr from "flatpickr";
import { Korean } from "flatpickr/dist/l10n/ko.js"; // 한국어 로케일 불러오기

export default class extends Controller {
  static targets = ["datetime"];

  connect() {
    if (this.hasDatetimeTarget) {
      this.initFlatpickr();
    }
  }

  initFlatpickr() {
    flatpickr(this.datetimeTarget, {
      enableTime: true,
      time_24hr: true, // 24시간 형식 사용
      minuteIncrement: 1,
      dateFormat: "Y-m-d H:i (D요일 K G:i)", // 기존 HTML5 datetime-local 포맷
      // altFormat: "Y년 m월 d일 h:i K", // 한국어 형식 (예: 2024년 12월 1일 2:30 PM)
      defaultDate: this.datetimeTarget.value || new Date(), // 기본값 설정
      locale: Korean, // 한국어 로케일 적용
      onChange: this.handleDateTimeChange.bind(this), // Flatpickr의 onChange 이벤트
    });
  }

  handleDateTimeChange(selectedDates, dateStr, instance) {
    // 사용자 정의 이벤트 트리거
    const event = new Event("blur", { bubbles: true });
    this.datetimeTarget.dispatchEvent(event);
  }
}