// <div data-controller="components--like">
//
// </div>
import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";
import helpers from "@/utils/helpers";

export default class extends Controller {
  static targets = [ "border", "fill", "text" ];

  setLikeCount(count) {
    this.textTarget.innerText = count;
  }

  onClickLike() {
    api.post(`/api/posts/${this.data.get("post-ext-id")}/like.json`).then((res) => {
      this.setLikeCount(res.data.count)
    }).catch((e) => console.log(e.message));
  }

  connect() {
  }
}