// <div data-controller="notes--publish">
//
// </div>
import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";
import helpers from "@/utils/helpers";

export default class extends Controller {
  static targets = [
    "emailTitle", "dateTime", "priceContainer", "memberPriceInput",
    "priceInput",
    "publishingRecipientType", "publishingPostingType",
    "sendMailContainer",
    "emailTitleInput", "emailTitleWarn", "hiddenSendTestSubmit",
    "previewDataContainer", "adultOnlyCheckBox",
    "enablePreviewDataCheckBox",
    "previewDataCustomEditor", "previewDataDefaultView", "previewDataAdultOnlyView",
    "webVisibilityContainer",
    "senderEmailAddressContainer", "senderEmailAddressSelect",
    "subscriptionGroupContainer", "subscriptionGroupSelect",
    "recipientContainer", "recipientDescription",
    "marketingEnabledRecipientContainer"
  ];

  static values = {
    publishingRecipientTypesI18n: Object
  }

  onEmailTitleChange(e) {
    this.emailTitleTarget.innerText = e.target.value;
  }

  onDateTimeChange(e) {
    const date = new Date(e.target.value);

    // 24시간 포맷 옵션
    const options24 = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      weekday: 'long',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false // 24시간 포맷
    };

    // 12시간 포맷 옵션
    const options12 = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true // 12시간 포맷
    };

    // 포맷터 생성
    const formatter24 = new Intl.DateTimeFormat('ko-KR', options24);
    const formatter12 = new Intl.DateTimeFormat('ko-KR', options12);

    // 포맷팅된 24시간제 날짜 및 시간
    const parts24 = formatter24.formatToParts(date);
    const year = parts24.find(p => p.type === 'year').value;
    const month = parts24.find(p => p.type === 'month').value;
    const day = parts24.find(p => p.type === 'day').value;
    const weekday = parts24.find(p => p.type === 'weekday').value;
    const hour24 = parts24.find(p => p.type === 'hour').value;
    const minute = parts24.find(p => p.type === 'minute').value;

    // 포맷팅된 12시간제 시간 및 오전/오후
    const parts12 = formatter12.formatToParts(date);
    const hour12 = parts12.find(p => p.type === 'hour').value;
    const dayPeriod = parts12.find(p => p.type === 'dayPeriod').value;

    // 최종 포맷 조합
    const formattedDate = `${year}-${month}-${day} ${hour24}:${minute} (${weekday} ${dayPeriod} ${hour12}:${minute})`;

    this.dateTimeTarget.innerText = formattedDate;
  }


  checkEmailTitleAd(e) {
    if (e.target.value.includes("광고")) {
      this.emailTitleWarnTarget.innerText = "뉴스레터 제목에 '광고' 라는 단어가 포함되어 있네요. 실제로 광고가 포함되어 있으시다면 광고 문구를 반드시 넣어야 하지만, 그렇지 않은 경우에 넣으시면 광고가 아님에도 이메일이 프로모션 함으로 분류 될 가능성이 있습니다. 또한 법적으로 광고성 이메일의 경우에는 명시적으로 광고 수신에 동의하신 분들에게만 보낼 수 있습니다. 광고 수신 동의 기능의 경우 '브랜딩 플랜' 이상 부터 사용할 수 있으며, 수신 동의 하지 않은 사람들에게 광고를 보낼 경우 생기는 책임은 개별 뉴스레터에 있습니다.";
    } else {
      this.emailTitleWarnTarget.innerText = "";
    }
  }

  sendTest = (e) => {
    try {
      const clickedCommand = window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey;
      if (clickedCommand) {
        return;
      }
    } catch {
    }

    this.hiddenSendTestSubmitTarget.click();
  }

  togglePreviewDataEditor = () => {
    if (this.enablePreviewDataCheckBoxTarget.checked) {
      this.previewDataCustomEditorTarget.classList.remove("hidden");
      this.previewDataDefaultViewTarget.classList.add("hidden");
      this.previewDataAdultOnlyViewTarget.classList.add("hidden");
    } else {
      this.previewDataCustomEditorTarget.classList.add("hidden");
      if (this.isAdultOnly) {
        this.previewDataDefaultViewTarget.classList.add("hidden");
        this.previewDataAdultOnlyViewTarget.classList.remove("hidden");
      } else {
        this.previewDataDefaultViewTarget.classList.remove("hidden");
        this.previewDataAdultOnlyViewTarget.classList.add("hidden");
      }
    }
  }

  get checkedPublishingRecipientType() {
    const postingTypeRadio = document.querySelector('input[name="post[publishing_recipient_type]"]:checked')
    return postingTypeRadio && postingTypeRadio.value;
  }

  get checkedPublishingRecipientTypeDescription() {
    const postingTypeRadio = document.querySelector('input[name="post[publishing_recipient_type]"]:checked')
    return postingTypeRadio && postingTypeRadio.dataset.description;
  }

  get checkedPublishingPostingType() {
    const postingTypeRadio = document.querySelector('input[name="post[publishing_posting_type]"]:checked')
    return postingTypeRadio && postingTypeRadio.value;
  }

  get isAdultOnly() {
    return this.hasAdultOnlyCheckBoxTarget && this.adultOnlyCheckBoxTarget.checked;
  }

  onPublishingTypeBlur() {
    if (this.checkedPublishingPostingType === "product") {
      this.priceContainerTarget.classList.remove("hidden")
      this.priceInputTarget.required = true;
      if (this.hasMemberPriceInputTarget) {
        this.memberPriceInputTarget.required = true;
      }
    } else {
      this.priceContainerTarget.classList.add("hidden")
      this.priceInputTarget.required = false;
      if (this.hasMemberPriceInputTarget) {
        this.memberPriceInputTarget.required = false;
      }
    }

    if (this.checkedPublishingRecipientType === "none") {
      this.sendMailContainerTargets.forEach((target) => {
        target.classList.add("hidden")
      })
      this.senderEmailAddressContainerTarget.classList.add("hidden")
      this.webVisibilityContainerTarget.classList.add("hidden")
      this.recipientContainerTarget.classList.add("hidden");
    } else {
      this.sendMailContainerTargets.forEach((target) => {
        target.classList.remove("hidden")
      })
      this.senderEmailAddressContainerTarget.classList.remove("hidden")
      this.webVisibilityContainerTarget.classList.remove("hidden")
      this.recipientContainerTarget.classList.remove("hidden");
    }

    if (["product", "membership_only"].includes(this.checkedPublishingPostingType) || this.isAdultOnly) {
      this.previewDataContainerTarget.classList.remove("hidden")
      this.togglePreviewDataEditor();
    } else {
      this.previewDataContainerTarget.classList.add("hidden")
      this.togglePreviewDataEditor();
    }

    if (this.hasSubscriptionGroupContainerTarget) {
      if (this.checkedPublishingRecipientType === "subscription_group") {
        this.subscriptionGroupContainerTarget.classList.remove("hidden");
        if (this.hasSubscriptionGroupSelectTarget) {
          this.subscriptionGroupSelectTarget.required = true;
        }
      } else {
        this.subscriptionGroupContainerTarget.classList.add("hidden");
        if (this.hasSubscriptionGroupSelectTarget) {
          this.subscriptionGroupSelectTarget.required = false;
        }
      }
    }

    if (this.hasMarketingEnabledRecipientContainerTarget) {
      if (this.checkedPublishingRecipientType === "marketing_enabled") {
        this.marketingEnabledRecipientContainerTarget.classList.remove("hidden");
      } else {
        this.marketingEnabledRecipientContainerTarget.classList.add("hidden");
      }
    }

    if (this.checkedPublishingRecipientTypeDescription) {
      this.recipientDescriptionTarget.innerHTML = `✉️&nbsp;&nbsp;${this.checkedPublishingRecipientTypeDescription}`;
    }
  }

  connect() {
    this.onPublishingTypeBlur();
  }
}