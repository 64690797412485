// <div data-controller="posts--product">
//
// </div>
import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";
import helpers from "@/utils/helpers";

export default class extends Controller {
  static targets = [
    "productContainer",
    "askPurchaseContainer",
    "willPurchaseContainer"
  ];

  // afterScrollBottom = (e) => {
  //   if ((window.innerHeight + document.documentElement.scrollTop) + 100 >= document.body.scrollHeight) {
  //     this.productContainerTarget.classList.add("hidden");
  //   } else {
  //     this.productContainerTarget.classList.remove("hidden");
  //   }
  // };

  showPurchasing(e) {
    this.askPurchaseContainerTarget.classList.add("hidden", "sm:hidden");
    this.willPurchaseContainerTarget.classList.remove("hidden", "sm:hidden");
  }

  hidePurchasing(e) {
    this.askPurchaseContainerTarget.classList.remove("hidden", "sm:hidden");
    this.willPurchaseContainerTarget.classList.add("hidden", "sm:hidden");
  }

  closeProduct(e) {
    this.productContainerTarget.classList.add("hidden");
  }

  connect() {
  }

}